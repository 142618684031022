<template>
  <Dialog
    :visible="visible"
    :closeOnEscape="true"
    :closable="true"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <DataTable
      class="p-datatable-sm"
      :paginator="true"
      :rows="5"
      stripedRows
      :value="consignacoes"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
      responsiveLayout="scroll">
      <template #header>Consignações Negociadas</template>
      <template #empty> Nenhum consignação encontrado. </template>
      <Column field="mesReferencia" header="Mês/Ano" class="col-4">
        <template #body="{ data }">
          {{ ('0' + data.mesReferencia).slice(-2) }}/{{ data.anoReferencia }}
        </template>
      </Column>
      <Column :sortable="true" header="Consignatária Vendendora">
        <template #body="{ data }">
          {{
            typeof data.rubrica ? data.rubrica.consignataria.razaoSocial : ' - '
          }}
        </template>
      </Column>
      <Column :sortable="true" field="rubrica" header="Rubrica">
        <template #body="{ data }">
          {{ typeof data ? data.rubrica.rubrica : ' - ' }}
          -
          {{ typeof data ? data.rubrica.nome : ' - ' }}
        </template>
      </Column>
      <Column header="Contrato">
        <template #body="{ data }">
          {{ data.contrato ? data.contrato : '-' }}
        </template>
      </Column>
      <Column field="prazoTotal" header="Prazo Total" />
      <Column header="Valor">
        <template #body="{ data }">
          {{ data.valorParcela | formatarValor }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>
<script>
export default {
  props: {
    visible: { type: Boolean, required: true },
    consignacoes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  methods: {
    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {},

    onHide() {},
  },
}
</script>
