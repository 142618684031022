var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"closeOnEscape":true,"closable":true,"modal":true,"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"hide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fechar()}}},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"paginator":true,"rows":5,"stripedRows":"","value":_vm.consignacoes,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","rowsPerPageOptions":[5, 10, 25],"currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} consignações","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Consignações Negociadas")]},proxy:true},{key:"empty",fn:function(){return [_vm._v(" Nenhum consignação encontrado. ")]},proxy:true}])},[_c('Column',{staticClass:"col-4",attrs:{"field":"mesReferencia","header":"Mês/Ano"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(('0' + data.mesReferencia).slice(-2))+"/"+_vm._s(data.anoReferencia)+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"header":"Consignatária Vendendora"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data.rubrica ? data.rubrica.consignataria.razaoSocial : ' - ')+" ")]}}])}),_c('Column',{attrs:{"sortable":true,"field":"rubrica","header":"Rubrica"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(typeof data ? data.rubrica.rubrica : ' - ')+" - "+_vm._s(typeof data ? data.rubrica.nome : ' - ')+" ")]}}])}),_c('Column',{attrs:{"header":"Contrato"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.contrato ? data.contrato : '-')+" ")]}}])}),_c('Column',{attrs:{"field":"prazoTotal","header":"Prazo Total"}}),_c('Column',{attrs:{"header":"Valor"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("formatarValor")(data.valorParcela))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }